<template>
  <div class="home" :lang="$i18n.locale">
    <el-container class="homeContainer">
      <el-aside class="homeAside" :width="istoggle + 'px'">
        <div
          class="img"
          style="text-align:center;font-size:32px;display:flex;justify-content:center;align-items:center;"
        >
          <!-- logo -->
           <img
            src="@/assets/images/FleeTan.png"
            alt
            style="height
          :85px;padding-left:0px;vertical-align:middle; width:166px "
          />
           
        </div>
        <!-- 菜单 -->
        <el-menu
          router
          :collapse="istoggle === 200 ? false : true"
          :default-active="index"
          class="el-menu-vertical-demo sideMenu"
          background-color="#545c64"
          text-color="#fff"
          @select="selectIndex"
          active-text-color="#ffd04b"
        >
				  <template v-for="(item,index) in menuList">
						<!-- 根据用户分菜单 -->
						<!-- 2025-03-17 特殊客户也有下单情况 暂定特殊客户拥有所有菜单 -->
						<template v-if="customInfo.deptId == 208 || item.deptId == customInfo.deptId">
							<!-- 有第二层菜单情况 -->
							<template v-if="item.children">
								<el-submenu :key="index + Math.random()" :index="item.name">
									<template slot="title">
										<i :class="item.icon"></i>
										<span>{{$t(item.title)}}</span>
										<el-badge v-if="item.name=='Rto' && $store.state.rtoData.amount > 0" :value="$store.state.rtoData.amount" :max="99" class="item"></el-badge>
									</template>
									<!-- 第二层 -->
									<template v-for="(secondItem,secondIndex) in item.children">
										<!-- 是否有第三层 -->
										<template v-if="secondItem.children">
											<el-submenu :key="secondIndex + Math.random()" :index="secondItem.name">
												<template slot="title">
													<i :class="secondItem.icon"></i>
													<span>{{$t(secondItem.title)}}</span>
												</template>
												<!-- 第三层 暂最多三层-->
												<template v-for="(thirdItem,thirdIndex) in secondItem.children">
													<el-menu-item :index="thirdItem.name">
														<i :class="thirdItem.icon"></i>
														<span slot="title">{{$t(thirdItem.title)}}</span>
													</el-menu-item>
												</template>
											</el-submenu>
										</template>
										<template v-else>
											<el-menu-item :index="secondItem.name">
												<i :class="secondItem.icon"></i>
												<span slot="title">{{$t(secondItem.title)}}</span>
												<el-badge v-if="secondItem.name=='RtoBind' && $store.state.rtoData.b2cQty > 0" :value="$store.state.rtoData.b2cQty" :max="99" class="item badgeSubItem"></el-badge>
												<el-badge v-if="secondItem.name=='RtoFbx' && $store.state.rtoData.fbxQty > 0" :value="$store.state.rtoData.fbxQty" :max="99" class="item badgeSubItem"></el-badge>
											</el-menu-item>
										</template>
									</template>
								</el-submenu>
							</template>
							<template v-else>
								<el-menu-item :index="item.name">
									<i :class="item.icon"></i>
									<span slot="title">{{$t(item.title)}}</span>
								</el-menu-item>
							</template>
						</template>
					</template>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="flexBet">
            <i :class="['font', iconfont]" @click="toggle"></i>
            <!-- 头部区域（可配合layui已有的水平导航） -->
            <div class="flexStart">
							<!-- zpy 2022-7-21 VIP国际化 选择语言 -->
							<el-dropdown @command="handleCommand">
								<span class="el-dropdown-link">{{$t('setting.languageType')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="zh">Zh - 中文</el-dropdown-item>
									<el-dropdown-item command="en">En - English</el-dropdown-item>
									<el-dropdown-item command="ar">Ar - بالعربية</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							
              <div class="flexStart flexStart1">
                <img class="headerImg" src="@/assets/images/header.jpg" alt />
                <i class="iconfont icon-04f"></i>
                <div>
                  {{username}}
                  <template></template>
                </div>
              </div>
							
              <!--  下拉菜单-->
              <el-dropdown>
                <!--  绑定指令,在methods里定义-->
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- icon是修改图标 ，command是点击后传给方法的值-->
                  <el-dropdown-item icon="el-icon-edit">
                    <span style="cursor:pointer;" @click="changePass">{{$t('setting.changePasswordOption')}}</span>
                  </el-dropdown-item>

                  <el-dropdown-item icon="el-icon-s-operation">
                    <span @click="logout" style="cursor:pointer;">{{$t('setting.loginOutOption')}}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--  下拉菜单结束-->
            </div>
          </div>
        </el-header>
        
				<ViewMain :menuList="menuList"></ViewMain>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { changeLanguage } from '@/assets/i18n/index'
import router from '@/router'
import ViewMain from './main.vue'
export default {
  name: "home",
	components:{
		ViewMain
	},
  data() {
    return {
      index: "/", // 当前选中的菜单项
      istoggle: 200, // aside 宽度
      iconfont: "el-icon-s-fold", // 字体图标
      creator: "",
      isCollapse: true,
      username: null,
      isShow: false,
		  
			menuList:[],
			customInfo:{
				prefixAwbNo:'',
				isSpecial:false,
				clientEnName:'',
				frontOrafter:'',//0前置，1后置
				clientShortName:'',
				clientId:0,
				deptId:209,// 默认VIP
			},
    };
  },
  methods: {
    //  左侧菜单折叠事件
    toggle() {
      if (this.istoggle === 200) {
        this.istoggle = 64;
        this.iconfont = "el-icon-s-unfold";
      } else {
        this.istoggle = 200;
        this.iconfont = "el-icon-s-fold";
      }
    },
    // 菜单激活回掉
    selectIndex(index) {
      // 存储当前激活的菜单项
      window.sessionStorage.setItem("index", index);
    },
		//获取客户的信息
		async getCustomerInfo() {
			let res = await this.$http.get("/toborder/customer/baseinfo/loginInfo").then(res => {
				if (res.code == 200) {
					let foa = '1';
					if(res.data.frontOrafter != '' && res.data.frontOrafter != null){
						foa = res.data.frontOrafter;
					}
					let deptId = 209;// 默认显示VIP菜单
					if(res.user && res.user.deptId != null && res.user.deptId != ''){
						deptId = res.user.deptId;
					}
					this.customInfo = {
						prefixAwbNo:res.data.prefixAwbNo,
						isSpecial:res.data.isSpecial,
						clientEnName:res.data.clientEnName,
						frontOrafter:foa,//0前置，1后置
						clientShortName:res.data.clientShortName,
						clientId:res.data.id,
						deptId:deptId,
					}
					// 获取菜单
					this.menuList = router.options.routes[3].children;
					window.sessionStorage.setItem("customInfo",JSON.stringify(this.customInfo));
					// 获取该客户待处理的RTO订单数量
					this.getClientPendingRTOInfo(res.data.prefixAwbNo);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
    // 获取客户未处理的RTO订单数量
    async getClientPendingRTOInfo(id) {
      const res = await this.$http.get("/toborder/customer/rto/fbx/qcNoActionNum?prefixAwbNo=" + id);
      if (res.code === 200) {
				// 将数据放状态管理
				this.$store.commit('setRTOOrderInfo',{
					amount:res.data.qcNoActionNum,
					b2cQty:0,
					fbxQty:res.data.qcNoActionNum,
				})
				
      } else {
        this.$message.error(res.msg);
      }
    },
    //  退出事件
    async logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push({
        path: "/login"
      });
			location.reload();
    },
    // 修改密码
    async changePass() {
      this.$router.push({
        path: "/changePassword"
      });
    },
		// VIP中英文切换显示
		handleCommand(type){
			const loading = this.$loading({
				lock:true,
				text:'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			changeLanguage(type);
			localStorage.setItem('vipLang',type);
			setTimeout(function(){
				loading.close();
			},1500)
		},
		
		
  },
  beforCreated() {},
  created() {
		this.menuList = [];
		
    this.index = window.sessionStorage.getItem("index");
    let params = window.sessionStorage.getItem("params");
		//获取该账号用户信息
		this.getCustomerInfo();
		
		
    //将JSON字符串转换成对象
    let oParams = JSON.parse(params);
    this.username = oParams.username;
    // console.log(oParams.username);

    // daohe@dlvd.com
    if (oParams.username == "hho@dlvd.com") {
      this.isShow = true;
    }
  },
  watch: {
    $route(to, from) {
      this.index = to.path.substr(1);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
.homeAside,
.homeContainer {
  height: 100%;
}

.homeAside {
  background-color: rgb(84, 92, 100);
}
.sideMenu{
	height: calc(100% - 60px);
	overflow-y: scroll;
	overflow-x: hidden;
}
.font {
  font-size: 25px;
}
.img {
  height: 60px;
  background-color: #fff;
}

.el-main {
  padding: 0;
}
.flexBet {
  height: 100%;
}
.el-aside {
  transition: width 0.5s;
}
.el-menu-vertical-demo {
  transition: width 0.5s;
}

//滚动条的宽度
::-webkit-scrollbar {
width: 2px;
height: 2px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
background-color: #828386;
border-radius: 3px;
}
/* .el-menu-vertical-demo  */
.el-menu {
  border: 0;
}
.headerImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.el-header {
  border: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: 700;
  .flexStart {
    height: 100%;
    position: relative;
    cursor: pointer;
    font-size: 14px;
  }
  .flexStart1::after,
  .flexStart2::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background-color: #5fb878;
    /* transition: width 0.5s; */
    transition: all 0.2s;
  }
  .flexStart1:hover::after {
    width: 96px;
  }
  .flexStart2:hover::after {
    width: 32px;
  }
}
.logout {
  margin-left: 20px;
}
.el-menu-item {
  text-align: left;
  padding: 0 60px 0 0;
}
.el-icon-truck:before {
  font-size: 25px;
}
 ::v-deep .el-menu-item-group__title{
	display: none;
}
 ::v-deep .el-badge{
	 position: absolute;
	 height: 30px;
	 width: 30px;
	 right: 40px;
 }
 ::v-deep .el-badge__content{
	border: 1px solid #ff6262;
 }
 ::v-deep .badgeSubItem{
	 right: 20px;
 }
 .el-dropdown{
	 margin-right: 15px;
 }
 
 
 // 阿拉伯语
 [lang="ar"] {
	 // 顶部功能
	 .el-dropdown{
	 	 margin: 0 5px 0 0;
	 }
	 .flexStart {
		 margin-right: 10px;
	 }
	 .el-dropdown .el-icon--right{
	 	 margin: 0 5px 0 0;
	 }
	 .el-dropdown-menu__item{
		 span {
			 margin-right: 5px;
		 }
	 }
	 
	 // 右侧菜单路由
	 .el-menu{
		 padding-right: 0px;
	 }
	 .el-menu-item{
		 text-align: right !important;
		 padding: 0 10px 0 20px !important;
		 span {
			 margin-right: 5px;
		 }
	 }
	 ::v-deep .el-submenu {
		 .el-submenu__title{
		 	 padding: 0 10px 0 20px !important;
		 }
		 .el-submenu__icon-arrow{
			 right: auto;
			 left: 20px;
		 }
		 .el-menu{
			 padding-right: 18px;
		 }
	 }
	 ::v-deep .el-menu--collapse{
		 .el-submenu__title{
		 	 padding: 0 20px 0 20px !important;
		 }
	 }
 }
</style>
